import * as React from 'react';

import { db } from "./utils/firebase";
import { ref, set, push } from "firebase/database";

import './App.css';

import ServiceCard from './ServiceCard.js';
import FAQCard from './FAQCard.js';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';

import ReCAPTCHA from "react-google-recaptcha";

import {useNavigate} from "react-router-dom"
import {useEffect} from "react"

const states = [
   {
      value: "AL",
      label: "Alabama",
   },
   {
      value: "AK",
      label: "Alaska",
   },
   {
      value: "AZ",
      label: "Arizona",
   },
   {
      value: "AR",
      label: "Arkansas",
   },
   {
      value: "CA",
      label: "California",
   },
   {
      value: "CO",
      label: "Colorado",
   },
   {
      value: "CT",
      label: "Connecticut",
   },
   {
      value: "DE",
      label: "Delaware",
   },
   {
      value: "DC",
      label: "District of Columbia",
   },
   {
      value: "FL",
      label: "Florida",
   },
   {
      value: "GA",
      label: "Georgia",
   },
   {
      value: "HI",
      label: "Hawaii",
   },
   {
      value: "ID",
      label: "Idaho",
   },
   {
      value: "IL",
      label: "Illinois",
   },
   {
      value: "IN",
      label: "Indiana",
   },
   {
      value: "IA",
      label: "Iowa",
   },
   {
      value: "KS",
      label: "Kansas",
   },
   {
      value: "KY",
      label: "Kentucky",
   },
   {
      value: "LA",
      label: "Louisiana",
   },
   {
      value: "ME",
      label: "Maine",
   },
   {
      value: "MD",
      label: "Maryland",
   },
   {
      value: "MA",
      label: "Massachusetts",
   },
   {
      value: "MI",
      label: "Michigan",
   },
   {
      value: "MN",
      label: "Minnesota",
   },
   {
      value: "MS",
      label: "Mississippi",
   },
   {
      value: "MO",
      label: "Missouri",
   },
   {
      value: "MT",
      label: "Montana",
   },
   {
      value: "NE",
      label: "Nebraska",
   },
   {
      value: "NV",
      label: "Nevada",
   },
   {
      value: "NH",
      label: "New Hampshire",
   },
   {
      value: "NJ",
      label: "New Jersey",
   },
   {
      value: "NM",
      label: "New Mexico",
   },
   {
      value: "NY",
      label: "New York",
   },
   {
      value: "NC",
      label: "North Carolina",
   },
   {
      value: "ND",
      label: "North Dakota",
   },
   {
      value: "OH",
      label: "Ohio",
   },
   {
      value: "OK",
      label: "Oklahoma",
   },
   {
      value: "OR",
      label: "Oregon",
   },
   {
      value: "PA",
      label: "Pennsylvania",
   },
   {
      value: "RI",
      label: "Rhode Island",
   },
   {
      value: "SC",
      label: "South Carolina",
   },
   {
      value: "SD",
      label: "South Dakota",
   },
   {
      value: "TN",
      label: "Tennessee",
   },
   {
      value: "TX",
      label: "Texas",
   },
   {
      value: "UT",
      label: "Utah",
   },
   {
      value: "VT",
      label: "Vermont",
   },
   {
      value: "VA",
      label: "Virginia",
   },
   {
      value: "WA",
      label: "Washington",
   },
   {
      value: "WV",
      label: "West Virginia",
   },
   {
      value: "WI",
      label: "Wisconsin",
   },
   {
      value: "WY",
      label: "Wyoming",
   },
];



function Contact({handleClick}) {

  let navigate = useNavigate() 

  function handleSubmit(event) {

    event.preventDefault();

    if ( !name ) { alert ( 'Name is a required field' ); return; }
    if ( !phone ) { alert ( 'Phone number is a required field' ); return; }
    if ( !email ) { alert ( 'EMail is a required field' ); return; }
    if ( !address ) { alert ( 'Street Address is a required field' ); return; }
    if ( !city ) { alert ( 'City is a required field' ); return; }
    if ( !zip ) { alert ( 'Zip is a required field' ); return; }
    if ( !comments ) { alert ( 'Comments is a required field' ); return; }

    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    var rc = re.test(phone);
    if ( !rc ) { alert ( 'Phone number entered is not valid' ); return; }

    re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var rc = re.test(email);
    if ( !rc ) { alert ( 'Email entered is not valid' ); return; }

    const emailsRef = ref(db, 'emails/');
    const newEmailRef = push(emailsRef);
    const key = newEmailRef.key;

    set(ref(db, 'emails/' + key + '/'), {
      displayName: name,
      email: email,
      message: comments,
      phone: phone,
      address: address,
      city: city,
      state: state,
      zip: zip,
    });

    alert ( "Thank you for contacting us. We will respond within 48 hours" );

    handleClick(event,'home');

  }


  function onCaptchaChange(value) {

     setVerified ( true );

  }

  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [city, setCity] = React.useState('');
  const [state, setState] = React.useState('NJ');
  const [zip, setZip] = React.useState('');
  const [comments, setComments] = React.useState('');
  const [verified, setVerified] = React.useState(false);

  useEffect(() => {
  }, []);

  return (
     <Container>
       <Grid container spacing={1}>
         <Grid item xs={12} md={12}>
            <Box sx={{ minWidth: 275, maxWidth: 1200 }} style={{ margin: "20px" }}>
               <Card>
                 <CardContent>
                    <Typography variant="h5" component="div" style={{ background: 'linear-gradient(to right bottom, #619362, #3f5f3f)', color: "#ffffff", padding: "10px" }}>
                    Contact Aquatech Irrigation
                    </Typography>
                    <p>
                    Aquatech Irrigation can provide you with a free estimate. This can be accomplished in one of two ways. You can schedule an appointment and we will come out to meet with you in person, or we can estimate your property without you having to be present. This can be accomplished by answering a few questions over the phone. We will leave your estimate and contact you later that day to discuss any questions or concerns you may have. Estimates can be requested by calling or email.
                    </p><hr />
                    <Typography sx={{ mb: 0.5 }} color="text.secondary" style={{ padding: "10px" }}>
                       <i>The protection of your privacy is important to us at Aquatech. Rest assured that the personal information you provide us with will not be disclosed to other parties and will be used solely for the purpose of bringing you the best service possible.</i>
                    </Typography>

                       <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>

                       <TextField required id="contactName" label="Name" style={{padding: "5px"}} onInput={ e=>setName(e.target.value)}/>
                       <TextField required id="contactPhone" label="Phone" style={{padding: "5px"}}  onInput={ e=>setPhone(e.target.value)}/>
                       <TextField required id="contactEmail" label="EMail" style={{padding: "5px"}} onInput={ e=>setEmail(e.target.value)}/>
                       <TextField required fullWidth id="contactAddress" label="Street address" style={{padding: "5px"}} onInput={ e=>setAddress(e.target.value)}/>
                       <TextField required id="contactCity" label="City" style={{padding: "5px"}} onInput={ e=>setCity(e.target.value)}/>
                       <TextField id="contactState" select label="State" defaultValue="NJ" style={{padding: "5px"}} onChange={ e=>setState(e.target.value)}>
                          {states.map((option) => (
                             <MenuItem key={option.value} value={option.value}>
                               {option.label}
                             </MenuItem>
                          ))}
                       </TextField>
                       <TextField required id="contactZip" label="Zip code" style={{padding: "5px"}} onInput={ e=>setZip(e.target.value)}/>
                       <TextField required multiline fullWidth rows={6} id="contactComments" label="Comments" style={{padding: "5px"}} onInput={ e=>setComments(e.target.value)}/>

                       <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={onCaptchaChange} />

                       {verified ? <Button type="submit" variant="contained">Send</Button> : <Button type="submit" disabled variant="contained">Send</Button> }

                       </Box>

                 </CardContent>
              </Card>
            </Box>
         </Grid>
         <Grid item xs>
           <ServiceCard handleClick={handleClick} />
         </Grid>
         <Grid item xs>
           <FAQCard handleClick={handleClick} />
         </Grid>
       </Grid>
     </Container>
  );

}

export default Contact;
