import * as React from 'react';

import banner from './images/banner.jpg';
import './App.css';

import AppBar from '@mui/material/AppBar';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import HomeIcon from '@mui/icons-material/Home';
import ServicesIcon from '@mui/icons-material/HomeRepairService';
import MailIcon from '@mui/icons-material/Mail';
import InfoIcon from '@mui/icons-material/Info';
import FAQIcon from '@mui/icons-material/Quiz';

function Header({value, handleChange}) {

  return (
    <AppBar position="static" className="App">
       <img src={banner} alt="banner" />
       <BottomNavigation value={value} onChange={handleChange}>
         <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} />
         <BottomNavigationAction label="Services" value="services" icon={<ServicesIcon />} />
         <BottomNavigationAction label="Contact" value="contact" icon={<MailIcon />} />
         <BottomNavigationAction label="About" value="about" icon={<InfoIcon />} />
         <BottomNavigationAction label="FAQ" value="faq" icon={<FAQIcon />} />
       </BottomNavigation> 
    </AppBar>
  );

}

export default Header;
