import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FAQIcon from '@mui/icons-material/Quiz';
import {Link} from 'react-router-dom'

export default function FAQCard({handleClick}) {
  return (
    <Box sx={{ minWidth: 275 }} style={{ margin: "20px" }}>
      <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" component="div" style={{ background: 'linear-gradient(to right bottom, #619362, #3f5f3f)', color: "#ffffff", padding: "10px" }}>
           Do you need service?
        </Typography>
        <Typography variant="body2" style={{ padding: "10px" }}>
           Having problems with your sprinkler system and not sure what to do? Browse our frequently asked questions to help determine your next step.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" startIcon={<FAQIcon />} onClick={(e) =>{handleClick(e,'faq')}}>Get Answers</Button>
      </CardActions>
      </Card>
    </Box>
  );
}
