import React from "react";

import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import './App.css';

import rainBird from './images/RainBirdLogo.gif';
import hunter from './images/hunter.gif';
import netafim from './images/netafim.gif';
import footerImg from './images/footer.jpg';

function Footer() {

  return (
    <AppBar position="static" className="App" style={{ background: '#ffffff' }}>
       <h3 style={{ background: 'linear-gradient(to right bottom, #619362, #3f5f3f)', color: "#ffffff" }}>CALL US TODAY ... 856 - 262 - 3109</h3>
       <div>
          <Link href="http://rainbird.com" rel="noreferrer" target="_blank" underline="none">
             <img src={rainBird} alt="rain bird" style={{ padding: '10px' }} />
          </Link>
          <Link href="http://hunterindustries.com" rel="noreferrer" target="_blank" underline="none">
            <img src={hunter} alt="hunter" style={{ padding: '10px' }}/>
          </Link>
          <Link href="http://netafim.com" rel="noreferrer" target="_blank" underline="none">
            <img src={netafim} alt="netafim" style={{ padding: '10px' }}/>
          </Link>
       </div>
       <img src={footerImg} alt="footerImg" style={{ padding: '10px' }}/>
    </AppBar>
  );
}

export default Footer;
