import * as React from 'react';

import './App.css';

import AboutCard from './AboutCard.js';
import ServiceCard from './ServiceCard.js';
import ContactCard from './ContactCard.js';
import FAQCard from './FAQCard.js';

import Container from '@mui/material/Container';

import Grid from '@mui/material/Grid';

function Home({handleClick}) {

  return (
     <Container>
       <Grid container spacing={1}>
         <Grid item xs>
           <AboutCard handleClick={handleClick}/>
         </Grid>
         <Grid item xs>
           <ServiceCard handleClick={handleClick}/>
         </Grid>
         <Grid item xs>
           <ContactCard handleClick={handleClick}/>
         </Grid>
         <Grid item xs>
           <FAQCard handleClick={handleClick}/>
         </Grid>
       </Grid>
     </Container>
  );

}

export default Home;
