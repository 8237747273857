import * as React from 'react';

import './App.css';

import ContactCard from './ContactCard.js';
import FAQCard from './FAQCard.js';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Grid from '@mui/material/Grid';

function Services() {

  return (
     <Container>
       <Grid container spacing={1}>
         <Grid item xs={12} md={12}>
            <Box sx={{ minWidth: 275, maxWidth: 1200 }} style={{ margin: "20px" }}>
               <Card>
                 <CardContent>
                    <Typography variant="h5" component="div" style={{ background: 'linear-gradient(to right bottom, #619362, #3f5f3f)', color: "#ffffff", padding: "10px" }}>
                    Frequently Asked Questions
                    </Typography>
                    <p>Do You Need service?</p>
                    <p>Questions to Ask Yourself</p>
                    <Typography sx={{ mb: 0.5 }} color="text.secondary" style={{ padding: "10px" }}>
                       <i>Please read the Frequently Asked Questions (FAQs) to see if any pertain to your situation. The information contained in each cause and/or solution is for informational purposes only. Do not use FAQ information as an instructional guide or training aid.</i>
                    </Typography>

                    <p><strong>What brands of sprinkler equipment does Aquatech install?</strong></p>
                    <p>Aquatech Irrigation has many years of experience installing and servicing all major sprinkler brands. If your project does not have a brand specification, we will suggest the use of the Rainbird or Hunter product line, both having unique advantages for different design applications.</p>

                    <p><strong>What is drip irrigation, and how can I benefit from it?</strong></p>
                    <p>Drip irrigation is a method where small amounts of water are placed directly on the root zone of plants, therefore using the water more efficiently. This is done with a network of small tubing that is all tied together, and provides water to many plants at once. Because the rate of water use for drip irrigation is used at a rate of gallons per hour, a drip zone will usually run up to an hour or more, not twenty or thirty minutes like the lawn sprinkler that water at a much higher rate. It is generally considered to be better for most planting beds than topical watering, and also more water efficient. Your particular situation would dictate whether it is right for you.</p>

                    <p><strong>Will water alone keep my lawn that deep green color?</strong></p>
                    <p>While water is a key ingredient for a healthy lawn, it is not the only answer. Fertilization is generally required for that lush green look for a healthy and greener lawn.</p>

                    <p><strong>What is a backflow device, and why do I need one on my sprinkler system?</strong></p>
                    <p>This device prevents water from the irrigation system from "flowing backwards" into the drinking water supply. By State plumbing code, a backflow prevention device is required for sprinkler systems that operate from a potable water supply such as city or well water. Our licensed Master Plumber will install this device if your new sprinkler system requires it.</p>

                    <p><strong>What happens when it rains?</strong></p>
                    <p>We recommend installing a rain sensor on all sprinkler systems. The sensor is set to automatically shut off the system when the amount of rain reaches a certain level. Once an appropriate level of dryness is reached, the system will automatically restart.</p>

                    <p><strong>What type of maintenance does the lawn sprinkler system require?</strong></p>
                    <p>Basic maintenance is required to ensure the effective operation of your system. The most important services are the fall winterization and the spring start-up. In the fall, we shut down the system and blow out the lines to eliminate the risk of freezing pipes. In the spring, we turn the system back on, evaluate each zone, adjust heads and reset the controller. Because weather conditions vary so greatly between the Spring start-up service and the Fall winterization, Aquatech strongly recommends that you consider both Spring start-up and Winterization services.</p>

                    <p><strong>How long does a system take to install?</strong></p>
                    <p>It would depend on the size of your yard. An average size lawn and landscape will normally take one to two days, depending on the individual site conditions. Installing a new irrigation system for a large country lawn or in extensive landscaping can take anywhere from two to five days or more. After the initial visit to the site, we will be able to estimate the amount of installation time needed at your site.</p>

                    <p><strong>Will the installation disrupt my lawn?</strong></p>
                    <p>We take great care during the installation process to make sure there is as little disruption to your property as possible. We use a vibratory plow to install the lines. Pipe and wires are "pulled" underground through a small slit in the turf to minimize damage to the lawn. We also carefully hand dig in flower beds and around utility markings. After a couple of weeks from the installation date, the only visible signs of your new sprinkler system should be a greener lawn and healthier plants.</p>

                    <p><strong>How do I benefit from an in-ground sprinkler system?</strong></p>
                    <p>Since hand watering is done on a random basis, and at irregular intervals, an automatic sprinkler system will actually, in most cases, use less water. It will also disperse the water at an even rate, and for a predetermined amount of time. This allows you to water sunny and shady areas for different periods of time, and to water when it is most efficient. One obvious benefit is the ability to continue a watering program while you are away.</p>
                 </CardContent>
              </Card>
            </Box>
         </Grid>
       </Grid>
     </Container>
  );

}

export default Services;
