import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ServicesIcon from '@mui/icons-material/HomeRepairService';
import {Link} from 'react-router-dom'

export default function ServiceCard({handleClick}) {

  return (
    <Box sx={{ minWidth: 275 }} style={{ margin: "20px" }}>
      <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" component="div" style={{ background: 'linear-gradient(to right bottom, #619362, #3f5f3f)', color: "#ffffff", padding: "10px" }}>
           Services  
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary" style={{ padding: "10px" }}>
          Residential and Commercial
        </Typography>
        <Typography variant="body2" style={{ padding: "10px" }}>
          <strong>Aquatech</strong> offers professional service technicians that are capable of repairing any system regardless who installed it or what brand of products were used. All service calls are scheduled with a 2 hour window (ex: 8am-10a) so there is no waiting around all day.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" startIcon={<ServicesIcon />} onClick={(e) =>{handleClick(e,'services')}}>Learn More</Button>
      </CardActions>
      </Card>
    </Box>
  );
}
