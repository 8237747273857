import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import {Link} from 'react-router-dom'

export default function AboutCard({handleClick}) {
  return (
    <Box sx={{ minWidth: 275 }} style={{ margin: "20px" }}>
      <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" component="div" style={{ background: 'linear-gradient(to right bottom, #619362, #3f5f3f)', color: "#ffffff", padding: "10px" }}>
           The Company
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary" style={{ padding: "10px" }}>
          Licensed and Insured
        </Typography>
        <Typography variant="body" component="div">
           <strong>Aquatech is a full service irrigation installation and maintenance company</strong>
        </Typography>
        <Typography variant="body2" style={{ padding: "10px" }}>
           <li>Servicing Gloucester, Camden, and Burlington counties</li>
           <li>Over 20 years experience</li>
           <li>Custom design and installation</li>
           <li>Family owned & operated</li>
           <li>Experienced & Qualified technicians</li>
           <li>Licensed and Fully Insured</li>
           <li>Quality installation and customer service</li>
           <li>5 year limited warranty</li>
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" startIcon={<InfoIcon />} onClick={(e) =>{handleClick(e,'about')}}>Learn More</Button>
      </CardActions>
      </Card>
    </Box>
  );
}
