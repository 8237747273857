import React from 'react'
import {  BrowserRouter as Router, Route, Routes, } from 'react-router-dom'

import Header from './Header'
import Footer from './Footer'

import Home from './Home'
import About from './About'
import Services from './Services'
import FAQ from './FAQ'
import Contact from './Contact'
import Main from './Main'

export default function AppRouter(props) {

  return (
    <Router>
      <Main />
    </Router>
  )

}

