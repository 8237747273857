import * as React from 'react';

import './App.css';

import ContactCard from './ContactCard.js';
import FAQCard from './FAQCard.js';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Grid from '@mui/material/Grid';

function Services({handleClick}) {

  return (
     <Container>
       <Grid container spacing={1}>
         <Grid item xs={12} md={12}>
            <Box sx={{ minWidth: 275, maxWidth: 1200 }} style={{ margin: "20px" }}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="div" style={{ background: 'linear-gradient(to right bottom, #619362, #3f5f3f)', color: "#ffffff", padding: "10px" }}>
                     Professional Services
                  </Typography>
                  <Typography variant="body" component="div" style={{ padding: "10px" }}>
                     <p>Aquatech irrigation professional service technicians are capable of repairing any system regardless of who installed it or what brand of products were used. All service calls are scheduled with a 2 hour window (ex: 8am-10a) so there is no waiting around all day.</p>
                     <p>Aquatech services Gloucester, Camden, and Burlington counties.</p>

                        <Typography variant="p" component="div" style={{ background: 'linear-gradient(to right bottom, #104c68, #488ab3)', color: "#ffffff", padding: "10px" }}>
                           <strong>Spring Startup</strong>
                        </Typography>
                        <p>Spring Startup is a thorough inspection of your system, including:</p>
                        <ul>
                        <li>Programming the controller for proper watering operation</li>
                        <li>Inspecting for leaks on the inside sprinkler plumbing as well as outside</li>
                        <li>Checking station valves for any malfunctions</li>
                        <li>Checking all sprinkler heads and adjust if needed</li>
                        <li>Reporting all repairs to you that may be necessary and their costs</li>
                        <li>Aquatech makes only the repairs authorized by you</li>
                        </ul>
                        <Typography variant="p" component="div" style={{ background: 'linear-gradient(to right bottom, #104c68, #488ab3)', color: "#ffffff", padding: "10px" }}>
                           <strong>Winterization</strong>
                        </Typography>
                        <p>Aquatech irrigation usually begins winterization service October 1st (weather permitting). Winterization is very important to your sprinkler system. We completely evacuate any water in the system so there is no chance of freezing. We maintain contact information for current customers and will contact via telephone to remind you it is time for the service to be scheduled. If you are not a current customer we will still be glad to provide the service and put you on our list at your request.</p>
                  </Typography>
                </CardContent>
              </Card>
            </Box>
         </Grid>
         <Grid item xs>
           <ContactCard handleClick={handleClick}/>
         </Grid>
         <Grid item xs>
           <FAQCard handleClick={handleClick}/>
         </Grid>
       </Grid>
     </Container>
  );

}

export default Services;
