import React from 'react'
import {  BrowserRouter as Router, Route, Routes, } from 'react-router-dom'

import Header from './Header'
import Footer from './Footer'

import Home from './Home'
import About from './About'
import Services from './Services'
import FAQ from './FAQ'
import Contact from './Contact'

import {useNavigate} from "react-router-dom"

export default function AppRouter(props) {

  let navigate = useNavigate() 

  const [value, setValue] = React.useState('home');

  const redirectToPage = (newValue: string) => {

    setValue(newValue);
    navigate ( "/" + newValue );

  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, newValue: string) => {

    redirectToPage(newValue);

  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {

    redirectToPage(newValue);

  };

  return (
    <>
    <Header value={value} handleChange={handleChange} />
    <Routes>
      <Route exact path='/' element={<Home handleClick={handleClick} />}></Route>
      <Route exact path='/home' element={<Home handleClick={handleClick}/>}></Route>
      <Route exact path='/about' element={<About handleClick={handleClick}/>}></Route>
      <Route exact path='/services' element={<Services handleClick={handleClick}/>}></Route>
      <Route exact path='/faq' element={<FAQ handleClick={handleClick}/>}></Route>
      <Route exact path='/contact' element={<Contact handleClick={handleClick}/>}></Route>
    </Routes>
    <Footer />
    </>
  )

}

