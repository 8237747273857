import * as React from 'react';

import './App.css';

import ServiceCard from './ServiceCard.js';
import ContactCard from './ContactCard.js';
import FAQCard from './FAQCard.js';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Grid from '@mui/material/Grid';

function About({handleClick}) {

  return (
     <Container>
       <Grid container spacing={1}>
         <Grid item xs={12} md={12}>
            <Box sx={{ minWidth: 275, maxWidth: 1200 }} style={{ margin: "20px" }}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="div" style={{ background: 'linear-gradient(to right bottom, #619362, #3f5f3f)', color: "#ffffff", padding: "10px" }}>
                     About Aquatech Irrigation
                  </Typography>
                  <Typography variant="body" component="div" style={{ padding: "10px" }}>
                    <p>Aquatech irrigation is state licensed and fully insured. We have over 20 years of experience. Our reputation is second to none when it comes to quality installation and customer service. State of NJ license # 0021214.</p>
                    <p><strong>Installation:</strong> Aquatech irrigation provides custom design and installation for each customers specific needs. Installations are performed in a timely professional manner with no disturbance to lawn or existing landscaping. All new installations come with a 5-year limited warranty.</p>
                    <p><strong>Estimates:</strong> Aquatech Irrigation can provide you with a free estimate. This can be accomplished in one of two ways. You can schedule an appointment and we will come out to meet with you in person. Or we can estimate your property without you having to be present. This can be accomplished by answering a few questions over the phone. We will leave you and estimate and contact you later that day to discuss any questions or concerns you may have. Estimates can be requested by calling or email.</p>
                  </Typography>
                </CardContent>
              </Card>
            </Box>
         </Grid>
         <Grid item xs>
           <ServiceCard handleClick={handleClick} />
         </Grid>
         <Grid item xs>
           <ContactCard handleClick={handleClick} />
         </Grid>
         <Grid item xs>
           <FAQCard handleClick={handleClick} />
         </Grid>
       </Grid>
     </Container>
  );

}

export default About;
