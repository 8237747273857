import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import {Link} from 'react-router-dom'

export default function ContactCard({handleClick}) {
  return (
    <Box sx={{ minWidth: 275 }} style={{ margin: "20px" }}>
      <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" component="div" style={{ background: 'linear-gradient(to right bottom, #619362, #3f5f3f)', color: "#ffffff", padding: "10px" }}>
           Want an estimate?  
        </Typography>
        <Typography variant="body2" style={{ padding: "10px" }}>
           If you would like a sprinkler system installed or need maintenence for your current system and don't know how much it will cost then contact us for a <strong>free estimate!</strong>
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" startIcon={<MailIcon />} onClick={(e) =>{handleClick(e,'contact')}}>Contact Us</Button>
      </CardActions>
      </Card>
    </Box>
  );
}
